<template>
    <div class="footer-ctn">
        <div>        <p style="font-size: 0.5rem; width: 10vw">
{{ date.creation }} - {{ date.actual }}+
<!-- all rights reserved -->
        </p></div>
        <div class="center">
        <p style="opacity: 0.8; font-size: 0.6rem">
            made with<a style="color: blue"> passion</a>, <a style="color: white">fingers  &</a> <a style="color: red">Love</a>
        </p>

        </div>
        <div class="fast-links-ctn">
        <!-- <img :src="@/assets/Interface/linkedin.png" alt=""> -->
        <img alt="Vue logo"
        class="social-ico"
        @click="GoToExternal()"
            src="@/assets/Interface/linkedin2.png"
            href="https://www.linkedin.com/in/julien-mentrard/"
            target="_blank"
        />
                <img alt="Vue logo"
                class="social-ico"
        @click="GoToExternal()"
            src="@/assets/Interface/youtube2.png"
            href="https://www.linkedin.com/in/julien-mentrard/"
            target="_blank"
        />
                        <img alt="Vue logo"
                        class="social-ico"
        @click="GoToExternal()"
            src="@/assets/Interface/codepen4.png"
            href="https://www.linkedin.com/in/julien-mentrard/"
            target="_blank"
        />
        <!-- <LinkedIn/> -->
    </div>
    </div>
</template>

<script>
// import LinkedIn from './icons/linkedIn.vue'
export default {
    name: 'ImPlumeWebsiteV2Footer',
    components: {
        // LinkedIn
    },
    data() {
        return {
            date: {
                creation: 1988,
                actual: new Date().getFullYear()
            }
        };
    },

    mounted() {
        
    },

    methods: {
        GoToExternal(){
            window.open('https://www.linkedin.com/in/julien-mentrard/', "_blank");
        }
    },
};
</script>

<style lang="scss" scoped>
.footer-ctn{
    background: green;
    width: 100%;
    height: 5rem;
    background: black;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 33vw;
    }
    .fast-links-ctn{
        display:flex;
        justify-content: space-evenly;
        height: 45%;
        img {
            height: 100%;
            margin-left: 1rem;
            // opacity: .7;
        }
    }
}
</style>