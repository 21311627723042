<template>
  <div id="app" class="flex">
    <div class="container"
     :class="{ containerMobile: mobile }">
      <div class="viewer">
    <Symbolme class="Symbolme-cpn"/>
<navigator class="nav-component "></navigator>
<Transition class="instruments" mode="out-in">
    <router-view
    class="routerView"
    :key="key"/>
</Transition>

    <div class="downShadow"/>
    </div>
    </div>
    <Footer class="footer-component container" style="margin-top:0;"
    :class="{footercptmobile: mobile}"/>
  </div>
</template>

<script>
import Footer from '@/components/Interface/Footer.vue';
import Navigator from '@/components/Interface/navigator.vue';
import Symbolme from '@/components/Interface/TriAngle.vue'
import consoleScript from '@/components/Interface/console/consoleScript.js';
// var mobile = require('is-mobile');
import mobile from 'is-mobile';
  // import cursor from '@/components/Interface/cursor/cursor.js'
  export default {
    name: "myApp", // Renaming the app so it won't conflict with the child component
    components: {
      Footer,
      Navigator,
      Symbolme
    },
    data() {
        return {
        mobile: this.$store.state.mobile
      }
    },
    mounted(){
      this.mobile = mobile()
      this.$store.state.mobile = this.mobile
      if(this.mobile){
      let htmlM = document.getElementsByTagName('html')[0]
      htmlM.style.fontSize = "3.5vmin"
      }
      consoleScript.header()
      consoleScript.menu()
    },
     computed: {
      key() {
        return this.$route.path;
      }
    }
  }
</script>

<style lang="scss">
@import 'https://fonts.googleapis.com/css?family=Press+Start+2P';
@import "./stylesheet/CRT.scss";
@import "./stylesheet/Global.scss";

#app {
  font-family: 'Press Start 2P',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
    color: green;
  // height: 100vh;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: black;
  margin: 0 !important;
  .flex{

  }
}
.container{
  margin: 0 auto;
  margin-top: 50px;
  width: 95vmin;
  height: 80vh;
  border: 0px solid black;
  border-top-left-radius: 100px 30px;
  border-top-right-radius: 100px 40px;
  border-bottom-left-radius: 100px 40px;
  border-bottom-right-radius: 100px 40px;
}
.containerMobile{
    margin-top: 10px;
    border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
.viewer{
  // padding-top: 3rem;
  height: 90vh;
  max-height: 90vh;
  overflow: scroll;
  -webkit-box-shadow: inset 0px 0px 50px 2px #000000; 
box-shadow: inset 0px 0px 25px 2px #000000;
// border-radius: 45px;
z-index:3;

.nav-component{
  position: absolute;
  // right: 0;
  // max-width: 90%;
  height: 2rem;
  z-index: 2;
// //   -webkit-box-shadow: 0px 17px 18px -4px #121010; 
// // box-shadow: 0px 17px 18px -4px #121010;
// // -webkit-box-shadow: 0px 17px 45px 44px rgba(18,16,16,0.98); 
// // box-shadow: 0px 17px 5px 24px rgba(18,16,16,0.98);
// -webkit-box-shadow: 0px 17px 45px 29px #121010; 
// box-shadow: 0px 17px 16px 19px #121010;
}
}
.downShadow{
  background: #121010;
  min-height: 1pt;
  min-width: 100%;
  position: absolute;
  bottom: 0;
  -webkit-box-shadow: 0px 17px 45px 29px #121010; 
box-shadow: 0px 0px 25px 19px #121010;
}
.footer-component{
  height: 10vh;
  position:absolute;
  bottom: 0;
}
.footercptmobile{
  bottom: initial
}
.Symbolme-cpn{
  position: absolute;
  // max-width: 100%;
  // border: 2px solid blue;
  height: 100%;
  z-index: 0;
    top: 50%; left: 50%; /* à 50%/50% du parent référent */
  transform: translate(-50%, -50%); 
  // opacity: 0.7;
}
.routerView{
  z-index:1;
  position: absolute;
    top: 50%; left: 50%;
  transform: translate(-50%, -50%); 
  padding-top: 10%;
}
html {
  font-size: 2.5vmin;
}
// @media screen and (min-width: 320px) {
//   html {
//     font-size: calc(100% + 6 * ((100vw - 320px) / 680));
//   }
// }
// @media screen and (min-width: 1000px) {
//   html {
//     font-size: 22px;
//   }
// }
/* we will explain what these classes do next! */
.v-enter{
  filter: blur(.1rem);
}
.v-enter-to{
    filter: blur(0rem);
}
.v-enter-active{
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  // filter: blur(0rem);
  // transition: opacity 3s reverse ease;
}

.v-leave-active {
  filter: blur(1.5rem);
  transition: opacity 1s ease;
}

.v-enter,
.v-leave-to {
  filter: blur(1.5rem);
  opacity: 0;
}
@keyframes blur {
  1% {
    filter: blur(0rem);
  } 99% {
    filter: blur(1.5rem);
    background: red;
  }
}

</style>
