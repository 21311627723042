function header() {
    //         let stylesArray= [
    //           'font-size: 20px;',
    //           'background-color: #121212;',
    //           'color: #cfc547;',
    //            'color: #121212;',
    //   'text-align: center;',
    //            'letter-spacing: 15px;',
    //            'text-shadow: -1px 1px 0 #41ba45, 1px 1px 0 #c63d2b, 1px -1px 0 #42afac, -1px -1px 0 red'].join(';')
    // console.log('%c PLüME dot C0M', stylesArray);
    console.log(
        '%c██████╗P██╗░L░ü░██╗░M░██╗███╗░E░███╗███████╗ \n██╔══██╗██║p░l░u██║░m░██║████╗e████║██╔════╝ \n██████╔╝██║░░░░░██║░░░██║██╔████╔██║█████╗░░ \n██╔═══╝░██║░░░░░██║░░░██║██║╚██╔╝██║██╔══╝░░ \n██║░░░░░███████╗╚██████╔╝██║░╚═╝░██║███████╗ \n╚═╝░░░░░╚══════╝░╚═════╝░╚═╝░░░░░╚═╝╚══════╝',
        'color: red'
    )
    console.log('%c█░█░█ ▄▀█ █▀   █░█ █▀▀ █▀█ █▀▀ \n▀▄▀▄▀ █▀█ ▄█   █▀█ ██▄ █▀▄ ██▄', 'color: red')
    // console.log('█░█ █▀█ █▀▄▀█ █▀▀   ▄▄   █▀█ █▀█ █▀█ ░░█ █▀▀ █▀▀ ▀█▀ █▀   ▄▄   ▄▀█ █▄▄ █▀█ █░█ ▀█▀   ▄▄   █▀▀ █▀█ █▄░█ ▀█▀ ▄▀█ █▀▀ ▀█▀ \n█▀█ █▄█ █░▀░█ ██▄   ░░   █▀▀ █▀▄ █▄█ █▄█ ██▄ █▄▄ ░█░ ▄█   ░░   █▀█ █▄█ █▄█ █▄█ ░█░   ░░   █▄▄ █▄█ █░▀█ ░█░ █▀█ █▄▄ ░█░')

    // console.log(
    //   //https://fsymbols.com/generators/carty/
    // '█░█░█ █░█ █▀█   █ █▀   █▀█ █░░ █░█ █▀▄▀█ █▀▀\n▀▄▀▄▀ █▀█ █▄█   █ ▄█   █▀▀ █▄▄ █▄█ █░▀░█ ██▄'
    // )
    // console.log(
    //   '%c__%s',
    //   'color: white; background:white; font-size:20px; width: 20px',
    //   '%c__', 'color: red; background:red; font-size:20px; width: 20px')
    // console.log('%c__', 'color: red; background:red; font-size:20px; width: 20px')
    // console.log(`%c example text`,`font-size: 40px; @import url(https://fonts.googleapis.com/css?family=Press+Start+2P'); font-family: 'PressStart2P';`)
    // console.log('%c WHO %s', 'color: red','%c IS %s', 'color: white','%c \nPLUME', 'color: BLUE; font-size: 30px;font-family:"Arial"' )        // console.log("%cWho","%cIs","%cPLUME"
    // // "background: #222; color: #bada55",
    // // "color: red; font-family:monospace; font-size: 20px",
    // // "color: red; font-family:monospace; font-size: 20px"
    // );
    // console.log("%cWho""color: red; font-family:monospace; font-size: 20px");
    // console.log("%cIs","color: darkblue; font-family:monospace; font-size: 20px");
    // console.log("%cPLUME","color: darkgreen; font-family:Arial; font-size: 20px");
    // console.log('\x1b[36m%s\x1b[0m', 'I am cyan'); // Cyan
    // console.log('\x1b[33m%s\x1b[0m', 'yellow'); // Yellow

}

function timeline() {
    console.group(('README.md'))
    console.log("Open differents logs to follow me from the install to my last update")
    console.groupEnd()
    console.log("")
    console.groupCollapsed('npm init')
    console.group('1988')
    console.warn('♒', 'a wild PLÜME appeared!', '🐣'),
        console.info('FR🇫🇷', '🌍Earth')
    console.groupEnd('')
    console.groupEnd('')
    // console.log("")
    console.groupCollapsed('npm list --dev')
    console.warn('1995', 'second-hand computer buy by my methematician lovely father')
    console.info(' 1996', 'second-hand gameboy')
    console.info(' 2000', 'PSX buy with my bro')
    console.info(' 2001', 'first instrument: guitar-bass')
    console.warn('2002', 'first website for my musical band', '(html/css/flash)'),
    console.info(' 2009 - 2013', 'Acheologic studies', '-François Rabelais university')
    console.info(' 2014 - 2015', 'Acheolomatic studies', '-François Rabelais university', '(python/SQL/arcgis)'),
    console.info(' 2015', 'Kayak longway trip', '(first time)'),

    console.warn(' 2017', 'dicovery of Processing,', 'Daniel Shiffman is a messiah'),
    console.warn(' 2018', 'Oscilloscope art for live music, my first lovely project to use coding', 'shows in West Europe', '//see it on Projects-Page')
    console.info('2018', 'first stage for my digital agency:', 'Bweez', 'Paris, France, Earth')
    console.log("")
    console.group('2018-2020 ', 'Apprenticeship contract')
    console.info('"Developper / Project Manager"', 'OpenClassRoom -really bad online school', '(Wordpress/Php')
    console.group('Employe for Bweez', '(NodeJS/VueJS/Quasar/[...]/Obseletes languages)')
    console.info('BWEEZ,', 'french retail design agency for luxury')
    console.info()
    console.info('* using ditigal solutions to invade real space with immersives experiences', '(Wordpress/Php')
    console.info('* hijack function of hightech solution for entertainment')
    console.info('www.bweez.com')
    console.groupEnd()
    console.groupEnd()
    console.groupEnd()
    console.groupCollapsed('npm run serve')
    console.warn(' 2018 -', new Date().getFullYear(), 'Still an happy employee in the Bweez Team')
    console.info('2022', 'reverse engineering on Obsolete french Minitel - 1981 terminal ', '//see it on Projects-Page')
    console.groupEnd()
    // console.log("")
    // console.log("")
    // console.log("")
}

function menu() {
    console.log('')
    // console.log('%c█░█ █▀█ █▀▄▀█ █▀▀   ▄▄   █▀█ █▀█ █▀█ ░░█ █▀▀ █▀▀ ▀█▀ █▀\n█▀█ █▄█ █░▀░█ ██▄   ░░   █▀▀ █▀▄ █▄█ █▄█ ██▄ █▄▄ ░█░ ▄█\n\n▄▀█ █▄▄ █▀█ █░█ ▀█▀   ▄▄   █▀▀ █▀█ █▄░█ ▀█▀ ▄▀█ █▀▀ ▀█▀\n█▀█ █▄█ █▄█ █▄█ ░█░   ░░   █▄▄ █▄█ █░▀█ ░█░ █▀█ █▄▄ ░█░', 'font-size:5px; color: green, height: 5px')
    console.log('%c𝚑𝚘𝚖𝚎 - 𝚙𝚛𝚘𝚓𝚎𝚌𝚝𝚜 %c- 𝚊𝚋𝚘𝚞𝚝 - %c 𝚌𝚘𝚗𝚝𝚊𝚌𝚝', 'font-size: 20px;color: red', 'font-size: 20px; color: red', 'font-size: 20px; color: red')
    // https://fsymbols.com/generators/carty/
    // console.log('')
    console.groupCollapsed('%c coming soon: navigation through the console version of this website', 'font-family:Arial; letter-spacing:5px; color: green; font-style: italic; opacity: 0.5; font-weight: 100; font-size: 10px')
    console.info('%c this%c.%cdocument%c.%clocation%c =%c "/move"', 'color: #9784D9', 'color:white', 'color: #D0C056', 'color: white', 'color:#D1C157', 'color: #BEC6CF', 'color:#F28B54')
    console.groupEnd()
    console.log('')
    // console.log(window.toolbar.visible)


    // console.log('TYPE -GO + {NUMBER} to nagivate between sections')
    const menu = [{
        "home": 1,
        "projects": 2,
        "about": 3,
        "contact": 4
    }]
        // console.table(menu)
}

    export default {header, timeline, menu}