<template>
    <div class="projectbanner-ctn">
        <img 
            class='projectbanner'
            :src="img"
            alt=""
        >
        <h3 class="project-title" :style="projectTitleStyle">{{ projectTitle }}</h3>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2BannerImg',
    props: {
        img: {
            type: String,
            default: 'no'
        },
        projectTitle: {
            type: String,
            default: 'no'
        },
        projectTitleStyle: {
            type: Object,
            default: {color: 'blue'}
        },
},
    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.projectbanner-ctn{
    position: relative;
    width: 100%;
    height: 100%;
.projectbanner{
    object-fit: cover;
    width: 100%;
    height: 100%;
    
}
.project-title{
    top: 50%; left: 50%;
  transform: translate(-50%, -50%); 
    position: absolute;
}
}

</style>