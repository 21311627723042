<template>
    <div class="portfolio-view">
        <!-- Portfolio -->
        <div v-if='projects' class="miniatures-ctn"
        :class="{ miniaturesCtnMobile: $store.state.mobile }">
            <div v-for="(project, index) in projects"
            :key="index"
            class="miniature"
            :class="{ miniatureMobile: $store.state.mobile }"
            >
                <div class="projectName" @click="pushTo(project.link, project.name)">
                    <p style="overflow-wrap: anywhere;">{{project.name}}</p>
                    <p v-if="!project.link" class="cs">coming soon</p>
                </div>
                <img class="img"
                    :src="project.img" alt=""
                >
            </div>
            
                        <div
            class="miniature"
            style='-webkit-box-shadow:inset 0px 0px 0px 5px green;-moz-box-shadow:inset 0px 0px 0px 5px green; box-shadow:inset 0px 0px 0px 5px green;'
            :class="{ miniatureMobile: $store.state.mobile }"
            >
                <div class="projectName" style="background: none"
                @click="pushTo('P5', 'P5')">
                    <p style="overflow-wrap: anywhere;">P5js graphics sketchs</p>
                    <!-- <p v-if="!project.link" class="cs">coming soon</p> -->
                </div>
                <!-- <img class="img"
                    :src="project.img" alt=""
                > -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2Portfolio',

    data() {
        return {
            projects: [
                // {
                //     name: '',
                //     link: '',
                //     img:''
                // }
                {
                    name: "valentine's day 2023",
                    link: 'Valentine2023',
                    img:this.img('valentine2023/love.gif')
                },
                {
                    name: 'Minitel 2.0',
                    link: 'Minitel',
                    img:this.img('minitel/imageonline-co-pixelated.png')
                },
                {
                    name: 'osci',
                    link: 'osci',
                    img:this.img('osci/osci.gif')
                },
                {
                    name: 'mobile site',
                    link: '',
                    img:this.img('gameboy/gb.png')
                },
                {
                    name: 'me.robot',
                    link: '',
                    img:this.img('merobot/merobot.png')
                },
                {
                    name: 'L1F3',
                    link: 'L1F3',
                    img:this.img('life/tree.png')
                },
                {
                    name: 'BWEEZ',
                    link: 'BWEEZ',
                    img:this.img('bweez/bweez.png')
                }
            ]
        };
    },

    mounted() {
        
    },

    methods: {
        pushTo(a, b){

            if(b === 'me.robot'){
                // this.$router.push({ name: 'me.robot' })
            } else {
                try{

                    this.$router.push({ name: a })
                } catch (error) {

  // Expected output: ReferenceError: nonExistentFunction is not defined
  // (Note: the exact output may be browser-dependent)
}

            }
        },
        img(name) {
            let path =  require('../assets/portfolio/'+name)
            return path
        }
    },
};
</script>

<style lang="scss" scoped>
.portfolio-view{
    height: 90%;
    width: 100%;
    overflow: scroll;
    .miniatures-ctn{
        margin: 0 auto;
        margin-top : 1rem;
        // width: 90%;
        height: 80%;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        
        .miniature{
            flex: 0 0 1; /* explanation below */
            margin-left: 0.25rem;
            margin-right: .25rem;
            margin-top: .5rem;
            // border: 2px solid green;
            // border-radius: .6rem;
            height: 7rem;
            width: 9rem;
            position: relative;
            overflow: hidden;
            .projectName:hover{
                transition-duration: 1s;
                background: rgba(0, 128, 0, 0);
                  box-shadow: 0 0 1pt #ce6b00, 0 0 3pt #4aff182f;

                filter: blur(1rem);
                // height: 0;
                border-radius: 7rem;
            }
            .projectName::before, .projectName::after{
box-shadow: 0px 0px 10px #ce6b00, 0px 0px 20px #4aff182f;
            }
            .projectName{
                display: inline-block;
                word-break: break-word;
                // border-radius: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                transition-duration: .3s;
                position: absolute;
                top: 50%; left: 50%;
                transform: translate(-50%, -50%);
                margin: 0 auto;
                z-index: 2;
                color: white;
                height: 100%;
                width: 100%;
                background: rgba(0, 128, 0, .7);
                box-shadow: 0 0 1pt #ce6b00, 0 0 3pt #4aff182f;

                filter: blur(0rem);
                font-size: .8rem;
                .cs{
                    position: absolute;
                    // top: 0;
                    color: rgba(0, 0, 0, 0.406);
                    transform: rotate(45deg);
                    text-shadow:1px 1px 1px rgba(0, 0, 0, 0.593);
                }
                // width; 
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: .9;
                // width: 100%;
                // height: auto;
            }
        }

    }
}
.miniaturesCtnMobile{
    justify-content: center;

    }
                .miniatureMobile{
            height: 7rem;
            width: 8rem !important;
            justify-content: center;
        }
</style>