<template>
    <div class="fw fh article-view-ctn">
        <!-- <div class="article-header">
        <BannerImg :img="Banner" :projectTitle="Title.name" :projectTitleStyle="Title.style"/>
        <Hashtags class="fw" :hashtags="relatedTechnologies"/>
        </div> -->
        <div class="article-ctn">
            <div class="article-introduction">
                
                <h5 class="article-subtitle blink">Under construction - comeback later</h5>
                <h4 class='article-title' style='color: darkred'>5 years of graphics and immersives sketchs</h4>
                <h5>this section will be updated later;
                    <br><br>In love with creative coding, i'v got lot of aesthetics projects in css+js or p5.js to sort, select, update, refactor before opening my favorite part
                    <br><br>See you soon ;)
                </h5>
            </div>
            <!-- <div class="article-corpus">
                <h5>The scope is to preserve the appareance & evolve it with modern features. I will use it as a living room computer, for music, videos, tiny research, for friends&Me.</h5>
                </br>
                <h4>step 1: (done)</br>
                    - convert the electronical keyboard into a usb keyboard by remapping the electronic board
                </h4>
                <h4>step 2: (done)</br>
                    - configure the raspberry with a retro-style interface
                </h4>
                <h4>step 3: (waiting for delivery)</br>
                    - remove original 220v cathodic-ray screen (that break my heart :/ ) to install a 5V screen;
                    - add a touchframe
                </h4>
                <h4>step 4: (optionnal)</br>
                    - build a lithium battery integrated into the frame
                </h4>
            </div> -->
        </div>
    </div>
</template>

<script>
import Hashtags from '@/components/Portfolio/Hashtags.vue'
import BannerImg from '@/components/Portfolio/Banner-img.vue';
export default {
    name: 'L0V32023',
    components: {
        BannerImg,
        Hashtags
    },
    data() {
        return {
           Title: {name: 'L0V3 IS THE 4NSW3R',
            style:{
                color: 'deeppink',
                fontSize: '2rem',
                textShadow: '1px 1px 2px red'
            },
        },
        Banner: require('@/assets/portfolio/valentine2023/violet.jpeg'),
        relatedTechnologies: ['p5.js', 'node.js', 'generative', '2D', 'immersive', 'arduino', 'sensors', 'heartbeat', 'fingerprint', 'kinect', 'realtime', 'mapping', 'videoprojector', 'L0V3','love']
 
        };
    },

    mounted() {
        
    },

    methods: {
        consoler(a){
            console.warn(typeof a)
        }
    },
};
</script>

<style lang="scss" scoped>
.article-view-ctn{
    display: flex;
    flex-direction: column;
}
.article-header{
    height: 40%;
}
.article-ctn{
    // border: 1px solid blue;
    height: 37%;
    overflow: scroll;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    font-size: .6rem;
    line-height: 1rem;
    .article-title{
        color: white;
        font-size:1rem;
        line-height: 2rem;
                text-shadow:1px 1px 1px red;

    }
    .article-subtitle{
        color: deeppink;
        text-shadow:1px 1px 1px violet;
    }
}
.article-corpus{
    color: rgba(255, 255, 255, 0.7);
    // max-width: 840px;
    text-align: left;
    margin-top: .5rem;
    h4{
        margin-bottom: 1rem;
    }
}

  .blink{
  animation: blinker 3s linear infinite;
  }

@keyframes blinker {

  50%{
    opacity: 0;
  }
}
</style>