<template>
  <div class="home">
    <br/><br/>

    <Quote style="min-width:90%;top: 0;"/>


  <div class="alert">as my life,<p class="alert-more">this site is under coding,<br/>be patient</p></div>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  </div>
</template>

<script>
// @ is an alias to /src
// src/assets/Interface/symbol.svg

import Quote from '../components/Quote.vue';
import HelloWorld from '@/components/HelloWorld.vue'
import CRT from '../components/CRT.vue';
export default {
  name: 'Home',
  components: {
    HelloWorld,
    CRT,
    Quote
  }
}
</script>
<style scoped lang="scss">
.home{
  width: 100%;
  height: 100%;
  .alert{
    color: rgb(199, 199, 199);
    margin-top: 2rem;
    line-height: 1rem;
    font-size: .7rem;
    text-shadow: 0 0 2pt #ffffff2f, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f, 0 0 10pt #49FF182f, 0 0 10pt #49FF182f, 0 0 17pt #4aff182f, 0 0 18pt #4aff182f;
    filter: grayscale(100%);
  }
}
</style>