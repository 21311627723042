<template>
    <div class="fw fh article-view-ctn">
        <div class="article-header">
        <BannerImg :img="Banner" :projectTitle="Title.name" :projectTitleStyle="Title.style"/>
        </div>
        <div class="article-ctn">
        <Hashtags class="fw" :hashtags="relatedTechnologies"/>
            <div class="article-introduction">
                <h4 class='article-title'>who are we?</h4>
                <h5 class="article-subtitle">Answering to the "simple" question is more complex than linking those 3 words together.</h5>
            </div>
            <div class="article-corpus">
                <h5>So I tryed to give a vision by using an heartbeat sensor as your personnal music score; A fingerprint sensor gave your unique biometrical algorythm.
                    By crossing your datas, a unique animation where shapes, shades, colors and mouvements is generated by you, from you, for you in real-time. 
                </h5>
                </br>
                <h5>A late night summer project used with a videoprojector mapped on the house facade ( +/- 9m x 4m ) where lines, particles are moving in avoiding windows (pre-defined)</h5>
            </div>
        </div>
    </div>
</template>

<script>
import Hashtags from '@/components/Portfolio/Hashtags.vue'
import BannerImg from '@/components/Portfolio/Banner-img.vue';
export default {
    name: 'ImPlumeWebsiteV2L1F3',
    components: {
        BannerImg,
        Hashtags
    },
    data() {
        return {
           Title: {name: 'Life project',
            style:{
                color: 'white',
                fontSize: '2rem',
                textShadow: '1px 1px 2px black'
            },
        },
        Banner: require('@/assets/portfolio/life/tree-of-life.jpg'),
        relatedTechnologies: ['p5.js', 'node.js', 'generative', '2D', 'immersive', 'arduino', 'sensors', 'heartbeat', 'fingerprint', 'kinect', 'realtime', 'mapping', 'videoprojector']
 
        };
    },

    mounted() {
        
    },

    methods: {
        consoler(a){
            console.warn(typeof a)
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../stylesheet/portfolio.scss";
</style>