<template>
    <div class="fw fh article-view-ctn">
        <div class="article-header">
        <BannerImg :img="Banner" :projectTitle="Title.name" :projectTitleStyle="Title.style"/>
        </div>
        <div class="article-ctn">
        <Hashtags class="fw" :hashtags="relatedTechnologies"/>
            <div class="article-introduction">
                <h4 class='article-title'>a digital sparkle</h4>
                <h5 class="article-subtitle">from coding to dreaming</h5>
            </div>
            <div class="article-corpus">
                <h5>My job is to make bridges between software and hardware to promote immersive experiences in retail environment.<br/>
                    <br/>Code UI,<br/>Weld electronics,<br/>Invade spaces.
                </h5>
            </div>
        </div>
    </div>
</template>

<script>
import Hashtags from '@/components/Portfolio/Hashtags.vue'
import BannerImg from '@/components/Portfolio/Banner-img.vue';
export default {
    name: 'ImPlumeWebsiteV2L1F3',
    components: {
        BannerImg,
        Hashtags
    },
    data() {
        return {
           Title: {name: 'BWEEZ',
            style:{
                color: 'white',
                fontSize: '4rem',
                textShadow: '1px 1px 2px black'
            },
        },
        Banner: require('@/assets/portfolio/bweez/transparent.png'),
        relatedTechnologies: ['node.js', 'Vue.js', 'raspi', 'front-end', 'AR', 'IoT', 'current-job']
 
        };
    },

    mounted() {
        
    },

    methods: {
        consoler(a){
            console.warn(typeof a)
        }
    },
};
</script>
<style lang="scss" scoped>
@import "../../stylesheet/portfolio.scss";
</style>
<!-- <style lang="scss" scoped> -->
.article-view-ctn{
    display: flex;
    flex-direction: column;
}
.article-header{
    height: 40%;
}
.article-ctn{
    // border: 1px solid blue;
    height: 37%;
    overflow: scroll;
    width: 90%;
    margin: 0 auto;
    font-size: .6rem;
    line-height: 1rem;
    .article-title{
        color: white;
        font-size:1rem;
        line-height: 2rem;
                text-shadow:1px 1px 1px rgba(0, 128, 0, 0.644);

    }
    .article-subtitle{
        color: rgb(0, 174, 0);
        text-shadow:1px 1px 1px rgba(0, 128, 0, 0.644);
    }
}
.article-corpus{
    color: rgba(255, 255, 255, 0.7);
    // max-width: 840px;
    text-align: left;
    margin-top: .5rem;
    h4{
        margin-bottom: 1rem;
    }
}
</style>