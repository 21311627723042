<template>
  <div class="merobot-view"><h2 class="">
  are you human ?
    </h2>
  <h3>define your gender:</h3>
    <div class="question">
      
      <div v-for="(gender, index) in question.areYou"
        :key="index"
        class="user-answ" @click="selectAnswer(index)"
        :style="[index === selectedAnswer? {'background': 'blue', 'color':'white'} : {}]"
      >
        {{ gender }}
      </div>
    </div>
    <div class="answer">


<!-- <table>
    <thead>
        <tr>
            <th colspan="2">Artificial Intelligence processing</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td class="primary">compare models with database:</td>
            <td class="secondary"><div class="dots"><a v-for="(dot,i) in dots" :key="i">.</a></div></td>
        </tr>
    </tbody>
</table> -->
<!-- {{ dotter() }} -->
        <!-- <div class="">AI running: . . . . . . . .</div> -->
        <div v-if="selectedAnswer" class="loader">
          <div class="wording">compare models with database:</div>
          <div class="dots">{{ dots }}</div>
        </div>
      </div>
      <div v-if='selectedAnswer && processedDone' class="congratulations">
        YES, YOU.HUMAN.
      </div>
    </div>
  </div>
</template>

<script>
export default {
data() {
  return {
    dots:0,
    t: null,
      question: {
          areYou: ['M','F', 'Unde fined']
      },
      selectedAnswer: null,
      processedDone: false
    }
  },
  computed: {
    analyzed() {
      return this.selectedAnswer;
    }
  },
methods: {
  selectAnswer(value){
    this.selectedAnswer = value
    this.dotter()
  },
  dotter(){
    // setInterval(, 400)
    if (this.dots <100 && !this.t) {
    this.t = setInterval(() => {
        this.dots++;
    }, 10)
} else {
    clearInterval(this.t)
}
  //     setInterval(() => {
  //    this.dots++;
  //    console.log(this.dots)
  //    if(this.dots++ > 1000) {
  //     clearInterval()
  //    }
  //  }, 1);

// setInterval(function() {

//     }.bind(this), 1000);
  },
  addDot(){

    // this.dots=this.dots +1
  }
}
}
</script>

<style lang="scss">
.merobot-view{
    height: 90%;
    width: 100%;
}
.question{
    display: flex;
    justify-content: space-around;
}
.user-answ{
  animation-duration: 1s;
  background: none;
color: blue;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid green;
width: 5rem;
height: 5rem;
font-size: .7rem;
text-shadow:1px 1px 1px rgba(255, 255, 255, 0.644);
}

.answer{
  .processing{
      td, tr{
        border: 1px solid green;
      }
    .primary{
      width: 30%;
    }
    .secondary{
      height: 100%;
      background: blue;
      border: none;
      display: flex;
      align-items: center;
    }
    .loader{

      display: flex;
      .wording{
        width: 30%;
        text-align: end;
        font-size: .5rem;
      }
      .dots{
width: 100%;
overflow-wrap: anywhere;
      }
    }
  }
  .congratulations{
  animation: blinker 1s linear infinite;
  }
}

@keyframes blinker {
  1%{
    opacity:1;
  }
  50%{
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
}
</style>
