<template>
    <div class="fw fh article-view-ctn">
        <div class="article-header">
        <BannerImg :img="Banner" :projectTitle="Title.name" :projectTitleStyle="Title.style"/>
        </div>
        <div class="article-ctn">
        <Hashtags class="fw" :hashtags="relatedTechnologies"/>
            <div class="article-introduction">
                <h4 class='article-title'>Convert a minitel into a retro computer</h4>
                <h5 class="article-subtitle">Flagship of the early <a style='color: blue'>fr</a><a style='color: white'>en</a><a style='color: red'>ch</a> digital times in the 80'; that retrofuturist design make my heart beating.</h5>
            </div>
            <div class="article-corpus">
                <h5>The scope is to preserve the appareance & evolve it with modern features. I will use it as a living room computer, for music, videos, tiny research, for friends&Me.</h5>
                </br>
                <h3>step 1: (done)</h3>
                  <h4>  - convert the electronical keyboard into a usb keyboard by remapping the electronic board
                </h4>
                <h3>step 2: (done)</h3>
                    <h4>- configure the raspberry with a retro-style interface
                </h4>
                <h3>step 3: (waiting for delivery)</h3>
                   <h4> - remove original 220v cathodic-ray screen (that break my heart :/ ) to install a 5V screen;
                    - add a touchframe
                </h4>
                <h3>step 4: (optionnal)</h3>
                    <h4>- build a lithium battery integrated into the frame
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
import Hashtags from '@/components/Portfolio/Hashtags.vue'
import BannerImg from '@/components/Portfolio/Banner-img.vue';
export default {
    name: 'ImPlumeWebsiteV2L1F3',
    components: {
        BannerImg,
        Hashtags
    },
    data() {
        return {
           Title: {name: 'MINITEL',
            style:{
                color: 'white',
                fontSize: '2rem',
                textShadow: '1px 1px 2px black'
            },
        },
        Banner: require('@/assets/portfolio/minitel/minitel-old.jpg'),
        relatedTechnologies: ['raspi', 'py', 'arduino', 'adafruit', 'retro-engineering']
 
        };
    },

    mounted() {
        
    },

    methods: {
        consoler(a){
            console.warn(typeof a)
        }
    },
};
</script>
<style lang="scss" scoped>
@import "../../stylesheet/portfolio.scss";
</style>