<template>
    <div class="fw fh article-view-ctn">
        <div class="article-header">
        <BannerImg :img="Banner" :projectTitle="Title.name" :projectTitleStyle="Title.style"/>
        </div>

        <div class="article-ctn">
            <Hashtags class="fw" :hashtags="relatedTechnologies"/>
            <div class="article-introduction">
                <h4 class='article-title'>Make an analog style Vj'ing</h4>
                <h5 class="article-subtitle">how to illustrate analog techno music with analog stuffs</h5>
            </div>
            <div class="article-corpus">
                <h5>Maze <a href="https://maze-noiser.bandcamp.com/">-analog techno bandcamp-</a> makes sounds, i make live visuals.</h5>
                </br>
                <h3>discover the osci-power</h3>
                <h4>
                    By injecting 2 electrics signal into an oscilloscope, you have your x/y 2D system to place the green beam where you want. Make another coordinate point, alternate between the twice, turn the speed up and now you have a line !
                </h4>
             <h3>Electric signals are sound-signals</h3>
                <h4>
                    I apologize, it's not a 100% analog system. A computer is needed to help me to generate more complex visuals... but it generate sound. The best digital/analog output on most of devices is stereo soundcard. Got my X (left), got my Y (right).
                </h4>
            <h3>link to the beat</h3>
                <h4>
                    As audio signal, i can modify it in realtime with musicians tools;
                    for example, a reverb will generating noises around my vertices, a delay will producing a slapback visual;
                    I can combine two sounds to add more complexes effects. That was i can merge visual animations together.
                    But the best way i found to keep the beat goes on to recreate a psychomatic production is the use sidechain compressors.
                    Maze send me their kick, and each time the kick is firing, sounds from my animations are lowest.
                    lowest sound = smallest size of the oscilloscope.
                    This little hack permit to be always on the beat, even if animations aren't perfectly syncro.
                </h4>
                    <h3>broadcasting live</h3>
                <h4>
                    An oscilloscope is an output. No RCA, no HDMI, only a green beam on the screen.
                    The end of the Analog Age has some good things. I bought some CCTV cameras. Results are good also in dark ambiances, their analog output is RCA or BNC.
                    To go deeper into analog VJing, i use an 1991 Panasonic MX-50. That video-mixer can combine such as 4 primary analog entries, got some internal effect and a great video feedback (when to connect the output to the input, images enter into a crazy infinite loop). That increase possibilities, and i personnaly add another oscilloscope, and mixing animations as a dj on a turtable.
                </h4>
                    <h3>show must go on</h3>
                <h4>
                    The experience with <a href="https://maze-noiser.bandcamp.com/">Maze</a> enabled me to travel across Europe countries, and beyond this incredible human journey, that project was used to try to learn coding, check if that way will be for me,<br/>and SPOILER:
                    <h3 style="margin: 0 auto; width: 100%; text-align: center"> 1 L0V3 1T !</h3>
                </h4>
            </div>
        </div>
    </div>
</template>

<script>
import Hashtags from '@/components/Portfolio/Hashtags.vue'
import BannerImg from '@/components/Portfolio/Banner-img.vue';
export default {
    name: 'ImPlumeWebsiteV2L1F3',
    components: {
        BannerImg,
        Hashtags
    },
    data() {
        return {
           Title: {name: 'Osci',
            style:{
                color: 'white',
                fontSize: '2rem',
                textShadow: '1px 1px 2px black'
            },
        },
        Banner: require('@/assets/portfolio/osci/oscitri.png'),
        relatedTechnologies: ['MAZE', 'techno', 'oscilloscope', 'music', 'VJ', 'processing', 'real-time', 'Pure Data', 'Ableton', 'M4L', 'Blender', 'Lissajous', 'Tektronix', 'CCTV', 'MX-50', 'audio-signal']
 
        };
    },

    mounted() {
        
    },

    methods: {
        consoler(a){
            console.warn(typeof a)
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../stylesheet/portfolio.scss";
</style>