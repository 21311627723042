<template>
    <div 
    class="timer">
    <a>{{ time.hour }}</a>
    <a
        :style=" time.second%2 ? '' : 'opacity: 0'">:</a>
        <a>{{ time.minute }}</a>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2Clock',

    data() {
        return {
            time: {
                hour: null,
                minute: null,
                second: null
            }
        };
    },

    mounted() {
        
        this.updateTime();
    },

    methods: {
        updateTime(){
            setInterval(() => {
            this.getCurrentTime()
            }, 1000);
        },
        getCurrentTime(){
            let cd = new Date()
            this.time.hour = this.twoDigit(cd.getHours());
            this.time.minute = this.twoDigit(cd.getMinutes())
            this.time.second = cd.getSeconds();
        },
        twoDigit(value){
            let response = value
             if(response < 10){
                response = "0" + response;
             }
             return response
        }
    },
};
</script>

<style lang="scss" scoped>
.timer{
      color: yellow;
  text-shadow: 0 0 2pt #ffffff, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f, 0 0 10pt #49FF182f, 0 0 10pt #49FF182f, 0 0 17pt #4aff182f, 0 0 18pt #4aff182f;

    font-weight: 100;
    font-size: .6rem;
    text-align: end;
    margin-right: 5vw;
    // width: 10rem;
    a{
        margin: 0 !important;
        color: rgb(0, 207, 0);
    }
}
</style>