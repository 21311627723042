<template>
  <div style="">
    <div ref="canvas"></div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      script: null,
      ps: null,
      x: 0,
      y: 0,
      width: 500,
      height: 400,
      color: 122,
      canvas: null,
    };
  },
  mounted() {
    var elmnt = document.getElementsByClassName("Symbolme-cpn")[0];
    this.height = elmnt.offsetHeight    
    this.script = (p) => {
      this.x = 100;
      this.y = 100;

      p.setup = () => {
        this.canvas = p.createCanvas(1400, this.height, p.WEBGL);
        this.canvas.parent(this.$refs.canvas);
        p.frameRate(24);
      };

      p.draw = () => {
        p.clear()
        p.background('#121010');
        p.rotate((p.frameCount/500)*p.PI / 2.0);
        p.scale(1)
        p.noFill()
        p.stroke(0,75,0)
        p.torus(200, p.frameCount/2, 3, 3);
      };
//       p.windowResized = () => {
//   resizeCanvas(windowWidth, windowHeight);
// }
    };
    const P5 = require("p5");
    this.ps = new P5(this.script);
    // console.log(this.ps);
  },
  methods: {
    colorChanger() {
      const color = Math.floor(Math.random() * (255 - 0 + 1)) + 0;
      this.color = color;
      return color;
    },
    randomInt(min, max) {
      const rand = Math.floor(Math.random() * (max - min + 1)) + min;
      return rand;
    },
  },
};
</script>
