<template>
    <div class="quote-wording">
        <!-- We're not alone because you read me. Welcome -->
            <div class="quote-wording-introduction">Just some words before starting the exploration:</div>
            <div>
    <a>Coding</a> is not just a job, it's a <a>passionate</a> way of <a>life</a> that <a>teaches us</a> every day how many humans can be autonomous, <a>creative</a> and <a>powerful</a>.</div>
    <div> See <a>languages</a> for what <a>they are</a>: a succession of usages that allow us to <a>learn</a>, <a>discover</a>, <a>share</a> with <a>each</a>-<a>others</a>.</div><div>They are powerful <a>tools</a> that <a>give power back</a> to the <a>people</a>.</div>
    <div>We are <a>craftmans</a>, always targetting <a>emotions</a> to tryi to build a better world.</div><br>
    <div><a>Can you be a developper</a>?  <a>YES</a>!<br>Everybody can, and your best friend will always be your own <a>sensibility</a>.</div>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2Quote',

    data() {
        return {
            
        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.quote-wording{
    // width: 100%;
    font-size: .6rem;
    text-align: left;
    line-height: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    // min-height: 100%;
    .quote-wording-introduction{
        margin-bottom: 1rem;
        color: rgba(255, 255, 255, 0.636);
    }
    a{
        color: yellow;
        // color: #FFFFFF;
// background: #232323;
text-shadow: 0 0 2pt #ffffff2f, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f, 0 0 10pt #49FF182f, 0 0 10pt #49FF182f, 0 0 17pt #4aff182f, 0 0 18pt #4aff182f;
    }
}
</style>