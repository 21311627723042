import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Portfolio from '@/views/Portfolio.vue'
import Merobot from '@/views/Portfolio/Merobot.vue'
import L1F3 from '@/views/Portfolio/L1F3.vue'
import Minitel from '@/views/Portfolio/Minitel.vue'
import Osci from '@/views/Portfolio/Osci.vue'
import Valentine2023 from '@/views/Portfolio/Valentine2023.vue'
import BWEEZ from '@/views/Portfolio/BWEEZ.vue'
import P5 from '@/views/Portfolio/P5.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/Merobot',
    name: 'me.robot',
    component: Merobot
  },
  {
    path: '/portfolio/2018/L1F3',
    name: 'L1F3',
    component: L1F3
  },
    {
    path: '/portfolio/2022/Minitel.2.0',
    name: 'Minitel',
    component: Minitel
  },
    {
    path: '/portfolio/2017/Osci',
    name: 'osci',
    component: Osci
  },
  ,
    {
    path: '/portfolio/2023/L0V3-for-Valentine-s-Day',
    name: 'Valentine2023',
    component: Valentine2023
  },
    {
    path: '/portfolio/2018-2023/BWEEZ-retail-Design-for-luxury',
    name: 'BWEEZ',
    component: BWEEZ
  },
      {
    path: '/portfolio/P5-coming-soon',
    name: 'P5',
    component: P5
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
