<template>
    <div class="hashtags-ctn">
        <div v-for="hashtag in hashtags" :key="hashtag" class="hashtag"> #{{ hashtag }}</div>
    </div>
</template>

<script>
export default {
    name: 'ImPlumeWebsiteV2Hastags',
    props:{
        hashtags: {
            type: Array,
            default: []
        }
    },
    data() {
        return {

        };
    },

    mounted() {
        
    },

    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.hashtags-ctn{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: .5rem;
    line-height: .7rem;
    
    .hashtag{
        color: rgba(255, 255, 255, 0.7);
        margin-left: 1rem;
        text-shadow:1px 1px 2px green;
    }
    .hashtag:hover{
        color: white;
        text-shadow:1px 1px 6px green;
    }
}
</style>