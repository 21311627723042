<template>
    <div class='navigator-ctn'>
        <div class="nav-btns">
            <div v-for="(link, index) in router"
            :key="index"
            class="nav-btn"
            >
            <router-link
            :class="$route.name == link.name ? 'glowing-yellow': 'glowing-green'"
            :to="link.route">
            {{link.name}}
            </router-link>
        </div>
                <clock v-if="this.windowWidth > 640"/>

            </div>
    </div>
</template>

<script>
import clock from "@/components/Interface/clock/clock.vue";

export default {
  components: { clock },
    name: 'ImPlumeWebsiteV2Navigator',

    data() {
        return {
            clockPosition: null,
            windowWidth: window.innerWidth,
            router: [
                {
                    name: 'Home',
                    route: '/'
                },
                {
                    name: 'Portfolio',
                    route: '/portfolio'
                },
                {
                    name: 'About',
                    route: '/about'
                },
                // {
                //     name: 'Contact',
                //     route: '/contact'
                // }
            ]
        };
    },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },
    methods: {
            onResize() {
                const innerWidth = window.innerWidth
                this.innerWidth = innerWidth

    }
    },
};
</script>

<style lang="scss" scoped>
.navigator-ctn{
    height:2rem;
    max-width:100%;
    width: 100%;
    // background: #121010;
    font-size: .7rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
.nav-btns{
    width: 100%;
    display: flex;
    min-width: 50vw;
    .nav-btn{
        flex: 1 1;
        a:link {
  color: green;
}
a{
text-decoration: none !important;
}
/* visited link */
a:visited {
  text-decoration: none !important;
}

/* mouse over link */
a:hover {
  text-decoration: none !important;
}

/* selected link */
a:active {
  text-decoration: none !important;
}
    }
}
}
.glowing-yellow{
  color: yellow;
  text-shadow: 0 0 2pt #ffffff, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f, 0 0 10pt #49FF182f, 0 0 10pt #49FF182f, 0 0 17pt #4aff182f, 0 0 18pt #4aff182f;

}
.glowing-green{
  color: green;
  text-shadow: 0 0 2pt #ce6b00, 0 0 5pt #ffffff2f, 0 0 7pt #ffffff2f, 0 0 10pt #4aff182f;

}
</style>